import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import Logoicon from "../assets/images/SIAQ-logo-95px.svg";
// import Errorimage from "../assets/images/error-image.svg";
import { Link, useParams } from "react-router-dom";
import '../../pages/layouts/common-layout.css';

function ErrorPage(props) {
  const { errorType } = useParams();
  return (
    <>
      <Helmet title={"Access Denied | LA-Tracker"} />
     

      <section className="d-flex align-items-center justify-content-center page_404 box-404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="text-center">
                 <h1 className="text-center h-error">404</h1>
                <div className="four_zero_four_bg  mb-4">
                </div>

                <div className="contant_box_404 ">
                  <h3 className="h2">{errorType || "Look like you're lost"}</h3>

                  <p>the page you are looking for not avaible!</p>

                  <Link to={'/login'} className="link_404">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default ErrorPage;
