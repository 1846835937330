import styled from '@emotion/styled';
import React from 'react';
import { Github } from '../icons/Github.tsx';
import { Typography } from './Typography.tsx';
import packageJson from '../../../../package.json';
import LogoIcon  from '../logo-icon.svg';
import { useThemeOutlet } from "../../../../src/context/ThemeContext.js";
interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed?: boolean;
}

const StyledButton = styled.a`
  padding: 5px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #484848;
  text-decoration: none;
`;

const StyledSidebarFooter = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  color: white;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  /* background: #0098e5; */
`;

const StyledCollapsedSidebarFooter = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  color: white;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  /* background: #0098e5; */
`;

const codeUrl =
  'https://github.com/azouaoui-med/react-pro-sidebar/blob/master/storybook/Playground.tsx';




export const SidebarFooter: React.FC<SidebarFooterProps> = ({ children, collapsed, ...rest }) => {
  const { themeOutlet, toggleThemeOutlet } = useThemeOutlet();
  
  return (
    <div
      style={{
        // display: 'flex',
        // justifyContent: 'center',
        // paddingTop: '40px',
        // backgroundColor:'red',
      }}
      className={`${themeOutlet} sfooter`}
    >
      {collapsed ? (
   
        < div className='' style={{
          // backgroundColor:'red',
        }}>
         <img src={LogoIcon} alt="Logo" width={36} height={36} />
        </div>
      ) : (
        // <StyledSidebarFooter {...rest}>
        //   <div>
        //     <LogoIcon />
        //   </div>
        //   <Typography fontWeight={600} className='font-sidebar-btn'>Name</Typography>
        //   <div style={{ marginTop: '12px' }}>
        //     <StyledButton href={codeUrl} target="_blank">
        //       <Typography variant="caption" color="#607489" fontWeight={600}>
        //         LAMS
        //       </Typography>
        //     </StyledButton>
        //   </div>
        // </StyledSidebarFooter>

        <StyledSidebarFooter {...rest} className={`${themeOutlet} bg-footer`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <StyledLogo rtl={rtl}><Logoicon/></StyledLogo> */}
          <img src={LogoIcon} alt="Logo"  height={50} />
          {/* <Typography variant="subtitle1" style={{marginLeft:'4px',fontSize:'30px'}} fontWeight={800} color="#7839CD">
             LAMS
          </Typography> */}
        </div>
      </StyledSidebarFooter> 
      )}
    </div>
  );
};
