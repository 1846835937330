import React from 'react';
import { useThemeOutlet } from './ThemeContext';
import { themeSets } from './CustomThemeSet';

const ThemeSelector = () => {
  const { setTheme } = useThemeOutlet();

  const handleChange = (event) => {
    setTheme(event.target.value);
  };

  return (
    <div className="radio-container">
      <label className='mb-2 dash-text-ur'>Set Your Theme</label>
      {themeSets.light.map((theme, index) => (
        <div key={index}>
          <div className='d-flex align-items-center'>  
            <input
              type="radio"
              id={`theme-${index}`}
              name="theme"
              value={theme.themeName}
              onChange={handleChange}
            />
             <span className='mx-1 theme-ur py-1' htmlFor={`theme-${index}`}>{theme.themeDisplayName}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThemeSelector;
