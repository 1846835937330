import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { GiHamburgerMenu } from "react-icons/gi";
import "../../App.css";
import Swal from "sweetalert2";
import { AiOutlinePoweroff, AiOutlineLock } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/Userslice";
import { BsBell } from "react-icons/bs";
import { selectUser } from "../../redux/Userslice";
import ChangePassword from "./changePassword";
import BrokenImageChecker from "../../components/commonDataTable/brokenImageChecker";
import { CgColorBucket } from "react-icons/cg";
import { Dropdown } from "react-bootstrap";
import ThemeSelector from "../../context/ThemeSelector";
import { useThemeOutlet } from "../../context/ThemeContext";

function Navigation({
  setCollapsed,
  collapsed,
  setToggled,
  toggled,
  theme,
  handleThemeChange,
  Switch,
}) {
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleChangePasswordClick = () => {
    setShowChangePasswordModal(true);
  };

  const { themeOutlet, toggleThemeOutlet } = useThemeOutlet();
  const handleLogout = (e) => {
    // SuccessToast("Successfully logged out!!");
    e.preventDefault();
    Swal.fire({
      title: "Please confirm",
      text: "Do you want to logout this session?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout!",
      customClass: {
        popup: `${themeOutlet} custom-swal-popup`
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // SuccessToast("Successfully logged out!!");

        dispatch(logout());
        // SuccessToast("Successfully logged out!!");
      }
    });
    // dispatch(logout());
  };

  const profileImage =
    userData.userDetails.userImageList &&
    userData.userDetails.userImageList.length > 0
      ? userData.userDetails.userImageList.find(
          (image) => image.docTypeId === 1
        )?.documentName
      : "./usericon.svg";
      
  return (
    <>
      <Navbar expand="lg" className="px-lg-0 px-4 py-0 pt-1">
        <Container fluid className="custom-container">
          <Navbar.Brand href="#">
            <GiHamburgerMenu
              id="collapse"
              className={`d-none d-md-block ${
                theme === "dark" ? "dark-theme" : "light-theme"
              }`}
              onClick={() => setCollapsed(!collapsed)}
            />
            <GiHamburgerMenu
              id="toggle"
              className={`d-block d-md-none ${
                theme === "dark" ? "dark-theme" : "light-theme"
              }`}
              onClick={() => setToggled(!toggled)}
            />
            {/* <button className="sb-button" onClick={() => setToggled(!toggled)}>
								Toggle
							</button> */}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarNav" />

          {/* <div>
            <button type="button" className="icon-button">
              <span className="material-icons"><BsBell/></span>
              <span className="icon-button__badge">2</span>
            </button>
          </div> */}

          <div className="d-flex align-items-center">
            {/* <span className="mr-2">Dark Mode</span> */}
            <span className="mx-1 col-grey">☀</span>
            <Switch
              onChange={handleThemeChange}
              checked={theme === "dark"}
              height={15}
              onColor="#af50ac"
              offColor="#ddd"
              onHandleColor="#fff"
              offHandleColor="#af50ac"
              width={45}
            />
            <span className="mx-1 col-grey">☾</span>
            {/* <Switch
            onChange={handleThemeChange}
            checked={theme === "dark"}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
          /> */}
          </div>

          <Navbar.Collapse id="navbarNav">
            <Nav className="ms-md-auto gap-2 d-flex justify-content-center align-items-center">
              {/* themeselect options */}
              <Dropdown>
                <Dropdown.Toggle
                  // variant="light"
                  id="dropdown-custom-components"
                  className="font-theme custom-dropdown-toggle"
                >
                  <CgColorBucket className="font-theme col-grey" />
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  <div className="mx-2 py-2 px-2">
                    <ThemeSelector />
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              <NavDropdown
                align="end"
                title={
                  <>
                    <span
                      className={`${
                        theme === "dark" ? "dark-theme" : "light-theme"
                      }`}
                    >
                      <BrokenImageChecker
                        imageLink={profileImage}
                        width={20}
                        height={20}
                        alt="Profile"
                        roundImage = {true}
                      />

                      {/* <img src="./usericon.svg" width={20} alt="profile"/> */}
                      <i
                        className={`bi bi-person-fill me-2 ${
                          theme === "dark" ? "dark-theme" : "light-theme"
                        }`}
                      ></i>
                      <span
                        className={`user-name ${
                          theme === "dark" ? "dark-theme" : "light-theme"
                        }`}
                      >
                        {userData.userDetails.firstName}
                      </span>
                    </span>
                  </>
                }
                id="navbarDropdown"
              >
                {/* <NavDropdown.Item href="#">Account</NavDropdown.Item>
                <NavDropdown.Item href="#">Another action</NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  className="user-name d-flex justify-content-between"
                  onClick={handleChangePasswordClick}
                >
                  <span>Change Password</span>
                  <AiOutlineLock className="user-icon" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="user-name d-flex justify-content-between"
                  onClick={(e) => handleLogout(e)}
                >
                  <span>Logout</span>
                  <AiOutlinePoweroff className="user-icon in-active-hove-color" />
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ChangePassword
        show={showChangePasswordModal}
        handleClose={() => setShowChangePasswordModal(false)}
      />
    </>
  );
}

export default Navigation;
